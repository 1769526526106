<template>
  <div class="container-fluid dark">
    <div class="row vh-100">
      <div class="col-12 col-md-4 text-center py-5 d-md-flex d-none flex-column justify-content-center text-white bg-login"></div>
      <div class="row col-12 col-md-8 ms-1 text-center d-flex flex-column py-5 justify-content-center">

          <template v-if="showVideo">
            <div>
              <button @click="showVideo=false" class="btn btn-primary">Login</button>
            </div>
            <!-- VIDEO SMISA -->
            <div class="offset-md-1 col-12 col-md-10 video-container">
              <iframe
                  src="https://www.youtube.com/embed/HsZo2HkI5TM?controls=0"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen></iframe>
            </div>
            <!-- FIM VIDEO SMISA -->
          </template>

          <template v-else-if="!showVideo">
            <!-- LOGIN  -->
            <div class="offset-md-2 col-12 col-md-8">
              <img src="./../assets/logo2_dark.jpeg" class="img-fluid mb-5 w-75"/>
              <va-form id="login" ref="loginForm" v-on:keyup.enter="login()" class="bg-white p-3 rounded">
                <div class="fs-3">Login</div>
                <va-input
                    class="mb-4"
                    v-model="email"
                    placeholder="E-mail"
                    type="email"
                    bordered
                />
                <va-input
                    class="mb-4"
                    v-model="senha"
                    type="password"
                    placeholder="Senha"
                    bordered
                />
                <button @click="showVideo=true" class="btn btn-outline-secondary me-2">Voltar</button>
                <button @click="login()" class="btn btn-primary btn-block">Entrar</button>
              </va-form>
              <img src="./../assets/murphys_2.jpeg" class="img-fluid mt-5 w-50"/>
            </div>
            <!-- FIM LOGIN -->
          </template>

      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Login',
  data: () => {
    return {
      email: "",
      senha: "",
      showVideo: true
    };
  },
  methods: {
    login: function () {
      const {email, senha} = this
      this.$store.dispatch("auth/doLogin", {email, senha}).then(() => {
        this.$router.push(this.$route.query.nextUrl ?? {name: 'Dashboard'})
      }).catch((err) => {
        this.$vaToast.init({message: err.response.data.message, color: "danger"})
      })
    }
  },
}
</script>
<style scoped>
.bg-login {
  background-size: cover;
  background-position: center;
  background-image: url("~@/assets/bg_login.jpg")
}
.dark {
  background-color: black;
}
.va-input__content > input:not(:placeholder-shown) {
  color:  #ffffff!important;
}
.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}
.video-container iframe, .video-container object, .video-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
}
</style>