<template>
  <div class="flex">
    <va-card :bordered="false">
      <va-card-title>
        <va-breadcrumbs color="black">
          <va-breadcrumbs-item>
            <router-link :to="{name:'Dashboard'}" class="text-decoration-none text-dark">Painel Inicial</router-link>
          </va-breadcrumbs-item>
          <va-breadcrumbs-item label="Visualizar Relatórios"/>
        </va-breadcrumbs>
        <div class="btn btn-primary btn-sm" @click="add()" title="Novo">
          <i class="bi-plus"></i>
        </div>
      </va-card-title>
      <va-card-content>
        <table class="va-table va-table--hoverable w-100">
          <thead>
          <tr>
            <th>Período</th>
            <th class="d-none d-md-table-cell">IQA - Água</th>
            <th v-if="isAdmin">Criado por</th>
            <th>Alterado em</th>
            <th class="centered">Editar/Metas/Download/Excluir</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="relatorio in relatorios" :key="relatorio.id">
            <td>{{ relatorio.periodo }}</td>
            <td class="d-none d-md-table-cell">
              <div class="text-white centered">
                <div class="col-12 rounded-pill bg-primary" v-if="relatorio.agua.indic_iqa > 79">Ótima (IQA > 79)</div>
                <div class="col-12 rounded-pill bg-success" v-if="relatorio.agua.indic_iqa > 51 && relatorio.agua.indic_iqa <= 79">Boa (51 &lt IQA &le; 79)</div>
                <div class="col-12 rounded-pill bg-warning" v-if="relatorio.agua.indic_iqa > 36 && relatorio.agua.indic_iqa <= 52">Regular (36 &lt IQA &le; 51)</div>
                <div class="col-12 rounded-pill bg-danger" v-if="relatorio.agua.indic_iqa > 19 && relatorio.agua.indic_iqa <= 36">Ruim (19 &lt IQA &le; 36)</div>
                <div class="col-12 rounded-pill bg-purple" v-if="relatorio.agua.indic_iqa <= 19 && relatorio.agua.indic_iqa !== null">Péssima (IQA &le; 19)</div>
              </div>
            </td>
            <td v-if="isAdmin">{{ relatorio.usuario.nome }}</td>
            <td>{{ formatDateTime(relatorio.updatedAt) }}</td>
            <td class="centered">
              <div @click="edit(relatorio)" class="btn btn-primary btn-sm" title="Editar">
                <i class="bi-pen"></i>
              </div>
              <div @click="metas(relatorio)" class="btn btn-warning btn-sm ms-2" title="Metas">
                <i class="bi-graph-up"></i>
              </div>
              <div @click="download(relatorio)" class="btn btn-success btn-sm ms-2" title="Baixar">
                <i class="bi-download"></i>
              </div>
              <div @click="remove(relatorio)" class="btn btn-danger btn-sm ms-2" title="Remover">
                <i class="bi-trash"></i>
              </div>
            </td>
          </tr>
          <tr v-if="!relatorios.length"><td colspan="6">Nenhum relatório cadastrado</td></tr>
          </tbody>
        </table>
        <div class="d-flex justify-content-evenly" v-if="totalPages > 1">
          <va-pagination
              v-model="page"
              :pages="totalPages"
              size="small"
              color="secondary"
          />
        </div>
      </va-card-content>
    </va-card>

    <!--------- MODAL DELETE --------->
    <va-modal v-model="showModalDelete" hide-default-actions>
      <slot>
        <span class="text-primary">
          Confirma a exclusão do relatório <b> {{ relatorio.periodo }} </b> ?
        </span>
      </slot>
      <template #footer>
        <va-button @click="showModalDelete = false" outline class="mr-2">
          Cancelar
        </va-button>
        <va-button @click="confirmDelete()" color="warning">
          Excluir
        </va-button>
      </template>
    </va-modal>

  </div>
</template>
<script>
import relatorioService from "../../../services/relatorio-service";
import filters from "../../../services/filters";
import store from "../../../store";
import DownloadPDF from "../../../components/DownloadPDF";

export default {
  name: 'Relatorio',
  components: {DownloadPDF},
  mixins: [filters],
  data() {
    return {
      isAdmin: false,
      page: 1,
      totalPages: 1,
      relatorio: {},
      relatorios: [],
      showModalDelete: false,
      downloadID: null
    }
  },
  created() {
    const role = store.getters["auth/getRole"]
    this.isAdmin = (role === 'Admin')
    this.getRelatorios()
  },
  methods: {
    async getRelatorios() {
      const {data} = await relatorioService.get(this.page)
      if (data.erro)
        this.$vaToast.init({message: data.mensagem, color: 'warning'})
      else {
        this.relatorios = data.regs.docs
        this.totalPages = data.regs.pages
      }
    },
    add() {
      this.$router.push({name: "NovoRelatorio"})
    },
    edit(relatorio) {
      this.$router.push({name: "EditarRelatorio", params: {_id: relatorio.id}})
    },
    remove(relatorio) {
      this.showModalDelete = true
      this.relatorio = relatorio
    },
    metas(relatorio) {
      this.$router.push({name: "Metas", params: {_id: relatorio.id, _periodo: relatorio.periodo}})
    },
    download(relatorio) {
      let routeData = this.$router.resolve({name: 'DownloadRelatorio', params: {_id: relatorio.id}});
      window.open(routeData.href, '_blank');
    },
    async confirmDelete() {
      let {success} = await relatorioService.delete(this.relatorio.id)
      this.showModalDelete = false
      await this.getRelatorios()
      this.$vaToast.init({message: 'Relatório excluído', color: 'success'})
    }
  }
}
</script>
<style scoped>
.centered {
  text-align: center;
}
.bg-purple {
  background-color: rebeccapurple;
}
@media (max-width: 576px) {
  .va-table {
    font-size: 12px;
  }
  .va-table th, .va-table td {
    padding: 3px;
  }
}
</style>